.CategoryCardWrapper {
    background: #fff;
    border: 0;
    border-radius: 0.4rem;
    box-shadow: 0px 0px 7px 1px rgb(0 0 0 / 15%);
    -webkit-box-shadow: 0px 0px 7px 1px rgb(0 0 0 / 15%);
    -moz-box-shadow: 0px 0px 7px 1px rgb(0 0 0 / 15%);
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 12px 1px rgb(223 111 0 / 25%);
        -webkit-box-shadow: 0px 0px 12px 1px rgb(223 111 0 / 25%);
        -moz-box-shadow: 0px 0px 12px 1px rgb(223 111 0 / 25%);
    }

    img {
        border-top-left-radius: 0.4rem;
        border-top-right-radius: 0.4rem;
    }
}

.TitleCategory {
    font-size: 0.8rem;
    height: 80px;
    overflow: hidden;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    font-weight: bold;

    @media (min-width: 992px) {
        font-size: 1rem;
    }
}
